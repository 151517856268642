import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
// import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import CleanproConfirmButton from '../../../../assets/images/logo/CleanproConfirmButton.png';
import CleanproBackButton from '../../../../assets/images/logo/CleanproBackButton.png';
import YesButton from '../../../../assets/images/logo/YesButton.png';
import NoButton from '../../../../assets/images/logo/NoButton.png';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from 'react-i18next';
import './LaundroKiosk.css';
// import Faq from './Faq.js';
import { logo1Controller } from '../../../../dynamicController';

const mySwal = withReactContent(Swal);

const Register = ({ setPage, data, outletId, token, setUser, themeLogo }) => {
    const { t } = useTranslation();
    const [mobileNumber, setMobileNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    // const [showFaq, setShowFaq] = useState(false);

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 55000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 55000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 55000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const numberRegex = /^\d+$/;
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

        if (!mobileNumber) {
            mySwal.fire(t("Error"), t("Please enter phone number / email address"), 'error');
        } else if (numberRegex.test(mobileNumber) && data.country === "Taiwan" && (!numberRegex.test(mobileNumber) || mobileNumber.length !== 10 || mobileNumber.substring(0, 2) !== "09")) {
            mySwal.fire({
                title: t("Error"),
                icon: "error",
                html: t("Please enter the correct phone number format. Example: 09xxxxxxxx"),
            });
        } else if (numberRegex.test(mobileNumber) && data.country === "Malaysia" && (!numberRegex.test(mobileNumber) || mobileNumber.length <= 9 || mobileNumber.length >= 12 || mobileNumber.substring(0, 2) !== "01")) {
            mySwal.fire({
                title: t("Error"),
                icon: "error",
                html: t("Please enter the correct phone number format. Example: 01xxxxxxxx"),
            });
        } else if (mobileNumber.includes("@") && !emailRegex.test(mobileNumber)) {
            mySwal.fire(t("Error"), t("Invalid Email Address"), 'error');
        } else if (!mobileNumber.includes("@") && !numberRegex.test(mobileNumber)) {
            mySwal.fire(t("Error"), t("Invalid phone number / email address"), 'error');
        } else {
            setShowOverlay(true);
        }
    };

    const handleYes = () => {
        setLoading(true);

        axios.post('/api/kiosk/checkUser', {
            token,
            outletId,
            number: mobileNumber,
        }).then(response => {

            setLoading(false);

            if (response.data.status === "EXIST") {
                setUser(response.data.data.user);
                setPage('SelectService');
            } else if (response.data.status === "NEW") {
                setUser(null);
                setPage('SelectService');
            }

        }).catch(err => {
            setLoading(false);
            setShowOverlay(false);
            mySwal.fire(t('Error'), t(err.response.data.error), 'error');
        });
    };

    return (
        <div id="member-login-screen" className="d-flex flex-column justify-content-between align-items-center bg-custom text-white vh-100">
            <header className="d-flex flex-column w-100 p-3">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <img
                        src={logo1Controller(themeLogo)}
                        alt="main logo"
                        className="img-fluid"
                        style={{ height: '50px' }}
                    />
                    <div className="d-flex">
                        <img
                            src={HomeCleanpro}
                            alt="Home Icon"
                            className="img-fluid me-3"
                            style={{ height: '50px', cursor: 'pointer' }}
                            onClick={() => setPage('Start')}
                        />
                        {/* <img
                            src={FaqCleanpro}
                            alt="FAQ Icon"
                            className="img-fluid"
                            style={{ height: '50px', cursor: 'pointer' }}
                            onClick={() => setShowFaq(!showFaq)}
                        /> */}
                    </div>
                    {/* {showFaq && (
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                            <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                        </div>
                    )} */}
                </div>
                <div className="w-100 text-start mt-2">
                    {data && (
                        <h2 className="fs-4 fw-bold text-primary">
                            {data.outlet_fullname}
                        </h2>
                    )}
                </div>
            </header>

            <div className="content text-center d-flex flex-column justify-content-center align-items-center vh-100">
                <img
                    src={logo1Controller(themeLogo)}
                    alt="main logo"
                    className="img-fluid"
                    style={{ height: '70px'}}
                />
                <h4 className="mb-5">{t('Please enter your mobile phone number or e-mail')}</h4>
                <Form onSubmit={handleSubmit} className="d-flex flex-column align-items-center w-75">
                    <Form.Group controlId="mobileNumber" className="mb-4 w-100">
                        <Form.Control
                            type="text"
                            inputMode="text"
                            required
                            autoComplete="off"
                            placeholder={t('Enter Your Phone Number / Email Address')}
                            className="p-3 text-center rounded-pill border-0 bg-white text-dark"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                        />
                    </Form.Group>
                    <div className="buttons d-flex justify-content-center gap-8 w-100">
                        <div className="position-relative" style={{ width: '200px', height: '80px' }}>
                            <button
                                type="submit"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    padding: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <img
                                    src={CleanproConfirmButton}
                                    alt="Confirm Button"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '36px',
                                        fontWeight: '600',
                                        color: '#fff',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    {t('Confirm')}
                                </span>
                            </button>
                        </div>

                        <div className="position-relative" style={{ width: '200px', height: '80px' }}>
                            <button
                                type="button"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    padding: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                onClick={() => setPage('Start')}
                            >
                                <img
                                    src={CleanproBackButton}
                                    alt="Back Button"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                                <span
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontSize: '36px',
                                        fontWeight: '600',
                                        color: '#000',
                                        pointerEvents: 'none'
                                    }}
                                >
                                    {t('Back')}
                                </span>
                            </button>
                        </div>
                    </div>
                </Form>
                {data?.non_member_payment && (
                    <div className="mt-4">
                        <span
                            className="text-primary fw-bold cursor-pointer"
                            onClick={() => {
                                setUser(null);
                                setPage('SelectService');
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            {t('SKIP TO PAYMENT')}
                        </span>
                    </div>
                )}
            </div>

            {showOverlay && (
                <div className="d-flex flex-column justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100 bg-white bg-opacity-75" style={{ zIndex: 1050 }}>
                    <h2 className="text-dark mb-4" style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                        {mobileNumber.includes("@") ? t('Please verify your e-mail') : t('Please verify your number')}
                    </h2>
                    <h1 className="text-dark mb-5" style={{ fontSize: '2rem', fontWeight: '500' }}>{mobileNumber}</h1>

                    <div className="d-flex justify-content-center gap-4">
                        <div className="position-relative" style={{ width: '250px', height: '80px' }}>
                            {loading ? (
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">{t('Loading')}</span>
                                </div>
                            ) : (
                                <>
                                    <img
                                        src={YesButton}
                                        alt="Yes Button"
                                        className="img-fluid"
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleYes}
                                    />
                                    <span
                                        className="position-absolute top-50 start-50 translate-middle text-white"
                                        style={{ fontSize: '1.5rem', fontWeight: '600', pointerEvents: 'none' }}
                                    >
                                        {t('Yes')}
                                    </span>
                                </>
                            )}
                        </div>

                        <div className="position-relative" style={{ width: '250px', height: '80px' }}>
                            <img
                                src={NoButton}
                                alt="No Button"
                                className="img-fluid"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowOverlay(false)}
                            />
                            <span
                                className="position-absolute top-50 start-50 translate-middle text-white"
                                style={{ fontSize: '1.5rem', fontWeight: '600', pointerEvents: 'none' }}
                            >
                                {t('Cancel')}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
        outletId: state.kiosk.outletId,
        token: state.kiosk.token,
        themeLogo: state.theme.logo
    };
};

export default connect(mapStateToProps, null)(Register);