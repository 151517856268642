import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import io from 'socket.io-client';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import BackLogo from '../../../../assets/images/logo/BackLogo.png';
import { savePage } from "../../../../redux/actions/kioskActions";
import { saveTransactionId } from "../../../../redux/actions/paymentActions"
import { logo1Controller } from '../../../../dynamicController';

import './LaundroKiosk.css';
// import Faq from './Faq.js';

const mySwal = withReactContent(Swal);

const Payment = ({ sendThis, setPage, endpoint, userLogout, user, savePage, saveTransactionId, data, machine, themeLogo }) => {

    const [loading, setLoading] = useState(false);
    const [razerBody, setRazerBody] = useState(null);
    const [ghlBody, setGhlBody] = useState(null);
    // const [showFaq, setShowFaq] = useState(false);
    const [duitnowUrl, setDuitnowUrl] = useState("");
    const [transactionId, setTransactionId] = useState("");

    const { t } = useTranslation();
    const history = useHistory();

    const razerButtonClick = useRef();
    const ghlButtonClick = useRef();

    useEffect(() => {
        if (razerBody) {
            razerButtonClick.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [razerBody]);

    useEffect(() => {
        if (ghlBody) {
            ghlButtonClick.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ghlBody]);

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 55000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 55000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 55000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    useEffect(() => {
        if (transactionId) {
            const socket = io({ auth: { transactionId } });
            socket.on("duitnowPayment", (transactionId) => {
                history.push('/kioskv2thankyou');
            });

            return () => {
                socket.disconnect();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionId]);

    const handlePayment = (method) => {
        let sendToBackend = { ...sendThis };

        if (method === "etoken") {
            if (parseFloat(user.token) < (parseFloat(sendThis.paymentAmount) + parseFloat(sendThis.sms_amount))) {
                mySwal.fire(t("Error"), t("Insufficient e-Tokens"), "error");
                return;
            } else {
                sendToBackend = { ...sendThis, token: true };
            }
        } else if (method === "duitnow") {
            if (machine.duitnow) {
                sendToBackend = { ...sendThis, duitnow: true };
            } else if (machine.rhbduitnow) {
                sendToBackend = { ...sendThis, rhbduitnow: true };
            }
        }

        setLoading(true);
        savePage("Kioskv2")

        axios
            .post(endpoint, sendToBackend)
            .then((res) => {
                setLoading(false);
                saveTransactionId(res.data.transactionId)
                if (res.data.status === "rm") {
                    window.location.href = res.data.data;
                } else if (res.data.status === "razer") {
                    setRazerBody(res.data.data);
                } else if (res.data.status === "ghl") {
                    setGhlBody(res.data.data);
                } else if (res.data.status === "laundro") {
                    history.push('/kioskv2thankyou');
                } else if (res.data.status === "ampersandpay") {
                    setTransactionId(res.data.transactionId);
                    setDuitnowUrl(res.data.data);
                } else if (res.data.status === "duitnow") {
                    setTransactionId(res.data.transactionId);
                    setDuitnowUrl(res.data.data);
                } else if (res.data.status === "rhbduitnow") {
                    setTransactionId(res.data.transactionId);
                    setDuitnowUrl(`data:image/png;base64,${res.data.data.data}`);
                } else {
                    userLogout();
                }
            })
            .catch((err) => {
                setLoading(false);
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    };

    return (
        <div id="payment-selection-screen" className="d-flex flex-column align-items-center vh-100 bg-custom position-relative">
            <header className="d-flex flex-column w-100 p-3">
                <div className="d-flex justify-content-between align-items-start w-100">
                    <div className="d-flex flex-column align-items-start">
                        <div className="d-flex align-items-center">
                            <img
                                src={logo1Controller(themeLogo)}
                                alt="main logo"
                                className="img-fluid"
                                style={{ height: '50px' }}
                            />
                        </div>
                        {data && (
                            <h2 className="fs-4 fw-bold text-primary mt-2 text-start w-100">
                                {data.outlet_fullname}
                            </h2>
                        )}
                    </div>
                    <div className="d-flex" style={{ marginTop: '0' }}>
                        <img
                            src={HomeCleanpro}
                            alt="Home Icon"
                            className="img-fluid me-3"
                            style={{ height: '50px', cursor: 'pointer' }}
                            onClick={() => setPage('Start')}
                        />
                        <img
                            src={BackLogo}
                            alt="Back Icon"
                            className="img-fluid me-3"
                            style={{ height: '50px', cursor: 'pointer' }}
                            onClick={() => setPage('Machines')}
                        />
                        {/* <img
                            src={FaqCleanpro}
                            alt="FAQ Icon"
                            className="img-fluid"
                            style={{ height: '50px', cursor: 'pointer' }}
                            onClick={() => setShowFaq(!showFaq)}
                        /> */}
                    </div>
                    {/* {showFaq && (
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                            <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                        </div>
                    )} */}
                </div>
            </header>

            {duitnowUrl ? (
                <div className="qr-page d-flex flex-column align-items-center w-100">
                    <h2 className="text-dark fw-bold mt-5">{t('Scan the QR Code')}</h2>
                    <img src={duitnowUrl} alt="DuitNow QR Code" className="img-fluid mt-4" />
                    <Button
                        variant="danger"
                        className="mt-5 fw-bold fs-4 rounded-pill"
                        style={{ width: '300px', height: '60px' }}
                        onClick={() => setDuitnowUrl("")}
                    >
                        {t('Cancel')}
                    </Button>
                </div>
            ) : (
                <>
                    <h2 className="text-dark fw-bold mt-5 fs-2">{t('Payment Option')}</h2>

                    {
                        loading ? (
                            <div className="text-center">
                                <div
                                    className="spinner-border"
                                    style={{ color: "#004FC1" }}
                                    role="status"
                                >
                                    <span className="sr-only">Loading ...</span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="d-flex justify-content-center gap-4 mt-4">
                                    {
                                        machine.epayment && (
                                            <div
                                                className="d-flex flex-column justify-content-center align-items-center bg-primary text-white rounded p-3"
                                                style={{ width: '300px', height: '150px', cursor: 'pointer', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)' }}
                                                onClick={() => handlePayment("ewallet")}
                                            >
                                                <h4 className="fw-bold">{t('Ewallet')}</h4>
                                            </div>
                                        )
                                    }

                                    {/* {
                                        user && user.tokenSetting && (
                                            <div
                                                className="d-flex flex-column justify-content-center align-items-center bg-primary text-white rounded p-3"
                                                style={{ width: '300px', height: '150px', cursor: 'pointer', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)' }}
                                                onClick={() => handlePayment("etoken")}
                                            >
                                                <h4 className="fw-bold">{t('Etoken')}</h4>
                                            </div>
                                        )
                                    } */}

                                    {
                                        (machine.duitnow || machine.rhbduitnow) && (
                                            <div
                                                className="d-flex flex-column justify-content-center align-items-center bg-primary text-white rounded p-3"
                                                style={{ width: '300px', height: '150px', cursor: 'pointer', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)' }}
                                                onClick={() => handlePayment("duitnow")}
                                            >
                                                <h4 className="fw-bold">{t('DuitNow')}</h4>
                                            </div>
                                        )
                                    }
                                </div>

                                <Button
                                    variant="danger"
                                    className="mt-5 fw-bold fs-4 rounded-pill"
                                    style={{ width: '300px', height: '60px' }}
                                    onClick={() => setPage('Machines')}
                                >
                                    {t('Cancel')}
                                </Button>
                            </>
                        )
                    }

                    {
                        razerBody ? (
                            <div>
                                <form action={razerBody.paymentDomain} method="post">
                                    <input type="hidden" name="amount" value={razerBody.amount} />
                                    <input type="hidden" name="merchant_id" value={razerBody.merchant_id} />
                                    <input type="hidden" name="orderid" value={razerBody.orderid} />
                                    {razerBody.bill_name ? <input type="hidden" name="bill_name" value={razerBody.bill_name} /> : null}
                                    {razerBody.bill_email ? <input type="hidden" name="bill_email" value={razerBody.bill_email} /> : null}
                                    {razerBody.bill_mobile ? <input type="hidden" name="bill_mobile" value={razerBody.bill_mobile} /> : null}
                                    <input type="hidden" name="bill_desc" value={razerBody.bill_desc} />
                                    <input type="hidden" name="country" value={razerBody.country} />
                                    <input type="hidden" name="vcode" value={razerBody.vcode} />
                                    <input type="hidden" name="currency" value={razerBody.currency} />
                                    <input type="hidden" name="returnurl" value={razerBody.returnurl} />
                                    <input type="hidden" name="callbackurl" value={razerBody.callbackurl} />
                                    <input type="hidden" name="cancelurl" value={razerBody.cancelurl} />

                                    <input type="submit" name="submit" value="Pay with razer" hidden ref={razerButtonClick} />
                                </form>
                            </div>
                        ) : null
                    }

                    {
                        ghlBody ? (
                            <div>
                                <form name="frmPayment" method="post" action={ghlBody.frmPayment}>
                                    <input type="hidden" name="TransactionType" value={ghlBody.TransactionType} />
                                    <input type="hidden" name="PymtMethod" value={ghlBody.PymtMethod} />
                                    <input type="hidden" name="ServiceID" value={ghlBody.ServiceID} />
                                    <input type="hidden" name="PaymentID" value={ghlBody.PaymentID} />
                                    <input type="hidden" name="OrderNumber" value={ghlBody.OrderNumber} />
                                    <input type="hidden" name="PaymentDesc" value={ghlBody.PaymentDesc} />
                                    <input type="hidden" name="MerchantReturnURL" value={ghlBody.MerchantReturnURL} />
                                    <input type="hidden" name="Amount" value={ghlBody.Amount} />
                                    <input type="hidden" name="CurrencyCode" value={ghlBody.CurrencyCode} />
                                    <input type="hidden" name="CustIP" value={ghlBody.CustIP} />
                                    <input type="hidden" name="CustName" value={ghlBody.CustName} />
                                    <input type="hidden" name="CustEmail" value={ghlBody.CustEmail} />
                                    <input type="hidden" name="CustPhone" value={ghlBody.CustPhone} />
                                    <input type="hidden" name="HashValue" value={ghlBody.HashValue} />
                                    <input type="hidden" name="PageTimeout" value={ghlBody.PageTimeout} />

                                    <input type="submit" name="submit" value="Pay with GHL" hidden ref={ghlButtonClick} />
                                </form>
                            </div>
                        ) : null
                    }
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
        themeLogo: state.theme.logo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        savePage: (data) => dispatch(savePage(data)),
        saveTransactionId: (data) => dispatch(saveTransactionId(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);