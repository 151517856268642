import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import YesButton from '../../../../assets/images/logo/YesButton.png';
import NoButton from '../../../../assets/images/logo/NoButton.png';
import PaymentWasherLogo from '../../../../assets/images/logo/PaymentWasherLogo.png';
import { currency } from '../../../../dynamicController';

const LaundroWasher = ({ setPage, machine, amount, modeSelection, changeMode, getPaymentAmount, outlet, notificationType, email, number, mode, renderDuration, voucherId, discountCode, user, setSendThis, setShowOverlay, setEndpoint }) => {

    const { t } = useTranslation();
    const [showConfirmPayment, setShowConfirmPayment] = useState(false);

    const confirmPayment = () => {
        let sendThis = {
            amount,
            machine,
            paymentAmount: getPaymentAmount('paymentAmount'),
            outlet,
            duration: renderDuration(),
            notificationType,
            email,
            sms_amount: getPaymentAmount('sms'),
            number,
            mode,
            kioskv2: true,
        };
        let endPoint = '/api/nonuser/payment';

        if (user) {
            sendThis.voucherId = voucherId;
            sendThis.memberId = user.memberId;
            sendThis.discountCode = discountCode;
            sendThis.point_amount = getPaymentAmount('pointAmount');
            sendThis.point_currency = user.point_currency;
            endPoint = '/api/user/payment';
        }

        setSendThis(sendThis);
        setEndpoint(endPoint);
        setPage('Payment');
    };

    const WasherIcon = () => (
        <div className="d-flex flex-column align-items-center mb-4">
            <div
                className="position-relative bg-primary"
                style={{
                    width: '200px',
                    height: '200px',
                    borderRadius: '10px',
                    padding: '10px',
                }}
            >
                <img
                    src={PaymentWasherLogo}
                    alt="Washer"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                    }}
                />
                <span
                    className="position-absolute top-50 start-50 translate-middle fw-bold text-black"
                    style={{ fontSize: '1.5rem' }}
                >
                    {machine.name.split('-')[1]}
                </span>
            </div>
            <div
                className="text-white fw-bold text-center"
                style={{
                    backgroundColor: '#004FC1',
                    marginTop: '-10px',
                    borderRadius: '0 0 10px 10px',
                    width: '200px',
                    fontSize: '1rem',
                    padding: '5px 0',
                }}
            >
                {t(machine.type)} {machine.capacity}
            </div>
        </div>
    );

    const ModePriceBubble = () => (
        <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
                backgroundColor: '#e0f0ff',
                border: '2px solid #0000ff',
                borderRadius: '40px',
                width: '300px',
                height: '100px',
                fontSize: '1rem',
                color: '#000',
            }}
        >
            <span className="fw-bold">{t(mode)}</span>
            <span>
                {renderDuration()} {t('minutes')}
            </span>
            <span>
                {currency(machine.country)} {parseFloat(amount).toFixed(2)}
            </span>
        </div>
    );

    return (
        <div
            className="container-fluid position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', zIndex: 9999 }}
        >
            {!showConfirmPayment ? (
                <div className="container" style={{ maxWidth: '1200px' }}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center p-4">
                            <h2 className="fw-bold text-dark mb-5" style={{ fontSize: '1.8rem' }}>
                                {t('Please choose the temperature')}
                            </h2>

                            <div className="row justify-content-center mb-4">
                                <div className="col-auto">
                                    <WasherIcon />
                                </div>
                            </div>

                            <div className="row justify-content-center mb-4">
                                <div className="col-auto d-flex flex-column align-items-center">
                                    {modeSelection.map((m, index) => (
                                        <div
                                            key={index}
                                            className={`position-relative mb-3 rounded-pill ${mode === m.name ? 'border border-danger' : 'border border-primary'}`}
                                            style={{
                                                width: '300px',
                                                height: '70px',
                                                backgroundColor: '#E3F2FF',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                transition: 'all 0.3s ease',
                                            }}
                                            onClick={() => changeMode(m.name)}
                                        >
                                            <div className="d-flex flex-column align-items-center">
                                                <span
                                                    className="fw-bold text-primary"
                                                    style={{ fontSize: '1rem', marginBottom: '2px' }}
                                                >
                                                    {t(m.name)}
                                                </span>
                                                <span className="text-primary" style={{ fontSize: '0.9rem' }}>
                                                    {currency(machine.country)} {m.price}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-auto d-flex" style={{ gap: '1rem' }}>
                                    <div
                                        className="position-relative"
                                        style={{ width: '150px', height: '50px', cursor: 'pointer' }}
                                    >
                                        <img
                                            src={YesButton}
                                            alt="Proceed Button"
                                            className="w-100 h-100"
                                            onClick={() => setShowConfirmPayment(true)}
                                        />
                                        <span
                                            className="position-absolute top-50 start-50 translate-middle fw-bold text-white"
                                            style={{ fontSize: '0.9rem', pointerEvents: 'none' }}
                                        >
                                            {t('Proceed')}
                                        </span>
                                    </div>

                                    <div
                                        className="position-relative"
                                        style={{ width: '150px', height: '50px', cursor: 'pointer' }}
                                    >
                                        <img
                                            src={NoButton}
                                            alt="Cancel Button"
                                            className="w-100 h-100"
                                            onClick={() => setShowOverlay(false)}
                                        />
                                        <span
                                            className="position-absolute top-50 start-50 translate-middle fw-bold text-white"
                                            style={{ fontSize: '0.9rem', pointerEvents: 'none' }}
                                        >
                                            {t('Cancel')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container" style={{ maxWidth: '1200px' }}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center p-4">
                            <h2 className="text-dark fw-bold mb-5" style={{ fontSize: '1.8rem' }}>
                                {t('Confirm Payment')}
                            </h2>

                            <div className="row justify-content-center mb-4">
                                <div className="col-auto">
                                    <WasherIcon />
                                </div>
                            </div>

                            <div className="row justify-content-center mb-4">
                                <div className="col-auto">
                                    <ModePriceBubble />
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-auto d-flex" style={{ gap: '1rem' }}>
                                    <div
                                        className="position-relative"
                                        style={{ width: '150px', height: '50px', cursor: 'pointer' }}
                                    >
                                        <img
                                            src={YesButton}
                                            alt="Pay Button"
                                            className="w-100 h-100"
                                            onClick={confirmPayment}
                                        />
                                        <span
                                            className="position-absolute top-50 start-50 translate-middle fw-bold text-white"
                                            style={{ fontSize: '0.9rem', pointerEvents: 'none' }}
                                        >
                                            {t('Pay')}
                                        </span>
                                    </div>

                                    <div
                                        className="position-relative"
                                        style={{ width: '150px', height: '50px', cursor: 'pointer' }}
                                    >
                                        <img
                                            src={NoButton}
                                            alt="Cancel Button"
                                            className="w-100 h-100"
                                            onClick={() => setShowConfirmPayment(false)}
                                        />
                                        <span
                                            className="position-absolute top-50 start-50 translate-middle fw-bold text-white"
                                            style={{ fontSize: '0.9rem', pointerEvents: 'none' }}
                                        >
                                            {t('Cancel')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LaundroWasher;