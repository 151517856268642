import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WasherLogo from '../../../../assets/images/logo/WasherLogo.png';
import VendingLogo from '../../../../assets/images/logo/VendingLogo.png';
import DryerLogo from '../../../../assets/images/logo/DryerLogo.png';
// import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import BackLogo from '../../../../assets/images/logo/BackLogo.png';
import { logo1Controller } from '../../../../dynamicController';
import './LaundroKiosk.css';
// import Faq from './Faq.js';

const SelectService = ({ onMachineTypeSelect, setPage, data, user, themeLogo }) => {
    const { t } = useTranslation();
    // const [showFaq, setShowFaq] = useState(false);

    const [hasWasher, setHasWasher] = useState(false);
    const [hasDryer, setHasDryer] = useState(false);
    const [hasVending, setHasVending] = useState(false);

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 25000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 25000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 25000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    useEffect(() => {
        if (data) {
            setHasWasher(data.machineData.some(machine => machine.type === 'Washer'));
            setHasDryer(data.machineData.some(machine => machine.type === 'Dryer'));
            setHasVending(data.machineData.some(machine => machine.type === 'Vending'));
        }
    }, [data]);

    return (
        <div id="select-service-screen" className="d-flex flex-column justify-content-between align-items-center bg-custom vh-100">
            <header className="d-flex flex-column w-100 p-3">
                <div className="d-flex justify-content-between align-items-center w-100">
                    <img
                        src={logo1Controller(themeLogo)}
                        alt="main logo"
                        className="img-fluid"
                        style={{ height: '50px' }}
                    />
                    <div className="d-flex">
                        <img
                            src={HomeCleanpro}
                            alt="Home Icon"
                            className="img-fluid me-3"
                            style={{ height: '50px', cursor: 'pointer' }}
                            onClick={() => setPage('Start')}
                        />
                        <img
                            src={BackLogo}
                            alt="Back Icon"
                            className="img-fluid me-3"
                            style={{ height: '50px', cursor: 'pointer' }}
                            onClick={() => setPage('Option')}
                        />
                        {/* <img
                            src={FaqCleanpro}
                            alt="FAQ Icon"
                            className="img-fluid"
                            style={{ height: '50px', cursor: 'pointer' }}
                            onClick={() => setShowFaq(!showFaq)}
                        /> */}
                    </div>
                    {/* {showFaq && (
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                            <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                        </div>
                    )} */}
                </div>
                <div className="w-100 text-start mt-2">
                    {data && (
                        <h2 className="fs-4 fw-bold text-primary">
                            {data.outlet_fullname}
                        </h2>
                    )}
                </div>
            </header>

            <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 w-100">
                <h2 className="text-primary fw-bold text-center mb-4">
                    {t("Welcome")}{user ? `, ${user.name}` : ""}
                </h2>
                <h3 className="text-dark text-center fw-bold mb-4">
                    {t("Please choose your service")}
                </h3>

                {hasWasher && (
                    <button
                        className="btn btn-primary d-flex justify-content-between align-items-center rounded-pill px-5 mb-3"
                        style={{ width: '60%', height: '100px', backgroundColor: '#007bff', outline: 'none', border: 'none' }}
                        onClick={() => onMachineTypeSelect('Washer')}
                    >
                        <span className="fw-bold fs-4">{t("WASHER")}</span>
                        <img src={WasherLogo} alt="Washer Logo" className="img-fluid" style={{ height: '50px' }} />
                    </button>
                )}

                {hasDryer && (
                    <button
                        className="btn btn-primary d-flex justify-content-between align-items-center rounded-pill px-5 mb-3"
                        style={{ width: '60%', height: '100px', backgroundColor: '#ff69b4', outline: 'none', border: 'none' }}
                        onClick={() => onMachineTypeSelect('Dryer')}
                    >
                        <span className="fw-bold fs-4">{t("DRYER")}</span>
                        <img src={DryerLogo} alt="Dryer Logo" className="img-fluid" style={{ height: '50px' }} />
                    </button>
                )}

                {hasVending && (
                    <button
                        className="btn btn-primary d-flex justify-content-between align-items-center rounded-pill px-5"
                        style={{ width: '60%', height: '100px', backgroundColor: '#d3d3d3', outline: 'none', border: 'none' }}
                        onClick={() => onMachineTypeSelect('Vending Machine')}
                    >
                        <span className="fw-bold fs-4">{t("VENDING MACHINE")}</span>
                        <img src={VendingLogo} alt="Vending Logo" className="img-fluid" style={{ height: '50px' }} />
                    </button>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
        themeLogo: state.theme.logo
    };
};

export default connect(mapStateToProps, null)(SelectService);