import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { changeLanguage } from "../../../../redux/actions/userActions";
import { logoController } from '../../../../dynamicController';
import './LaundroKiosk.css';

const StartScreen = ({ setPage, language, changeLanguage, data, themeLogo }) => {
    const { t } = useTranslation();

    const [regVoucherSetting, setRegVoucherSetting] = useState(null);

    useEffect(() => {
        if (data && data.voucherSetting) {
            const registerVoucherSetting = data.voucherSetting.filter(setting => setting.type === "Register")[0];
            if (registerVoucherSetting) {
                if (registerVoucherSetting.voucher_eligible) {
                    setRegVoucherSetting(registerVoucherSetting);
                }
            }
        }
    }, [data]);

    return (
        <div
            id="start-screen"
            onClick={() => setPage('Option')}
            className="vh-100 bg-startscreen text-white d-flex flex-column overflow-auto"
        >
            <div className="flex-grow-0 d-flex flex-column align-items-center pt-3">
                <div className="mb-2" style={{ maxWidth: '400px' }}>
                    <img
                        src={logoController(themeLogo)}
                        alt="main logo"
                        className="img-fluid"
                    />
                </div>

                <div className="h1 fw-bold mb-2">
                    {t('Touch to Start')}
                </div>
            </div>

            <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                {regVoucherSetting && (
                    <div className="text-center mb-2">
                        <div className="h2 fw-bold mb-1">
                            {t('New Users')}:
                        </div>
                        <div className="h3 fw-bold">
                            {regVoucherSetting.voucher_type === "Flat" ? (
                                <Trans i18nKey="Get {{ voucher_amount }} FREE Credits!">
                                    Get {{ voucher_amount: regVoucherSetting.voucher_amount }} FREE Credits!
                                </Trans>
                            ) : (
                                <Trans i18nKey="Get {{ voucher_amount }}% FREE!">
                                    Get {{ voucher_amount: regVoucherSetting.voucher_amount }}% FREE!
                                </Trans>
                            )}
                        </div>
                    </div>
                )}
            </div>

            <div className="flex-grow-0 d-flex flex-column align-items-center pb-3">
                <div className="text-center mb-3">
                    <p className="h5 fw-bold mb-1">{t("Reminder")}:</p>
                    <p className="h6">{t("Load your clothes into the washing machine & note the machine number")}</p>
                </div>

                <div className="d-flex justify-content-center gap-2 mb-2">
                    <Button
                        variant="outline-light"
                        active={language === "en"}
                        className="rounded-pill px-3 py-1"
                        onClick={(e) => {
                            e.stopPropagation();
                            changeLanguage('en');
                        }}
                    >
                        EN
                    </Button>
                    <Button
                        variant="outline-light"
                        active={language === "ms"}
                        className="rounded-pill px-3 py-1"
                        onClick={(e) => {
                            e.stopPropagation();
                            changeLanguage('ms');
                        }}
                    >
                        BM
                    </Button>
                    <Button
                        variant="outline-light"
                        active={language === "zh"}
                        className="rounded-pill px-3 py-1"
                        onClick={(e) => {
                            e.stopPropagation();
                            changeLanguage('zh');
                        }}
                    >
                        中文
                    </Button>
                </div>

                <div className="text-center">
                    <small className="fw-normal">{t('T&C Apply')}</small>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
        language: state.user.language,
        themeLogo: state.theme.logo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: (language) => dispatch(changeLanguage(language)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartScreen);