import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import birthday from "../../../assets/images/news/birthday.png";
import newUser from "../../../assets/images/news/newUser.png";
import referral from "../../../assets/images/news/referral.png";
import stamp from "../../../assets/images/news/stamp.png";
import token from "../../../assets/images/news/token.png";

import cleanproStampBanner from "../../../assets/images/news/cleanproStampBanner.jpg";
import cleanproBirthdayBanner from "../../../assets/images/news/cleanproBirthdayBanner.jpg";
import cleanproJGMBanner from "../../../assets/images/news/cleanproJGMBanner.jpg";
import cleanproMigrationBanner from "../../../assets/images/news/cleanproMigrationBanner.jpg";
import cleanproNewUserBanner from "../../../assets/images/news/cleanproNewUserBanner.jpg";
import cleanproReferralBanner from "../../../assets/images/news/cleanproReferralBanner.jpg";
import cleanproCuciEn from "../../../assets/images/news/cleanproCuciEn.jpg";
import cleanproCuciBm from "../../../assets/images/news/cleanproCuciBm.jpg";
import cleanproWelcome from "../../../assets/images/news/cleanproWelcomeV2.png";
import cleanprocope from "../../../assets/images/news/cleanproCope.jpg";
import cleanproLaundryPass from "../../../assets/images/news/cleanproLaundryPass.jpeg";

import washupBirthday from "../../../assets/images/news/washupBirthday.png";
import washupReferral from "../../../assets/images/news/washupReferral.png";
import washupStamp from "../../../assets/images/news/washupStamp.png";
import washupWelcome from "../../../assets/images/news/washupWelcome.png";

import i18n from "i18next";
import { Trans } from "react-i18next";
import { currency } from "../../../dynamicController";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const mySwal = withReactContent(Swal);

class NewsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      voucherSetting: null,
      referralSetting: null,
      news: [],
    };
  }

  componentDidMount() {
    if (this.props.operatorId && this.props.user) {
      axios
        .post("/api/user/getNews", { operatorId: this.props.operatorId })
        .then((res) => {
          this.setState({
            ...this.state,
            news: res.data,
            loading: false,
            voucherSetting: this.props.user.voucher_setting,
            referralSetting: this.props.user.referralSetting
          });
        })
        .catch((err) =>
          mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error")
        );
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    const title = () => {
      if (this.props.match.params.newsId === "newUser") {
        return i18n.t("Welcome Gift");
      } else if (this.props.match.params.newsId === "referral") {
        return i18n.t("Share Your Referral Code Now");
      } else if (this.props.match.params.newsId === "birthday") {
        return i18n.t("Birthday Gift for You");
      } else if (this.props.match.params.newsId === "token") {
        return i18n.t("Let's Pay with E-Token");
      } else if (this.props.match.params.newsId === "stamp") {
        return i18n.t("Collect 10 Stamps and Get Voucher");
      } else if (this.props.match.params.newsId === "welcomeToCleanproPlus") {
        return i18n.t("Welcome to Cleanpro Plus");
      } else if (this.props.match.params.newsId === "cleanproPayMigrationNotice") {
        return i18n.t("Cleanpro Pay Migration Notice");
      } else if (this.props.match.params.newsId === "cuciharihari") {
        return i18n.t("Cuci Hari-Hari Bersama Cleanpro!");
      } else if (this.props.match.params.newsId === "jawabGerentiMenang") {
        return i18n.t("Jawab & Gerenti Menang");
      } else if (this.props.match.params.newsId === "cleanproReferral") {
        return i18n.t("Share Your Referral Code");
      } else if (this.props.match.params.newsId === "cleanproBirthday") {
        return i18n.t("Birthday Gift for You");
      } else if (this.props.match.params.newsId === "cleanproStamp") {
        return i18n.t("Collect 10 Stamps and Get Voucher");
      } else if (this.props.match.params.newsId === "cleanproWelcome") {
        return i18n.t("Welcome Voucher");
      } else if (this.props.match.params.newsId === "cleanprocope") {
        return i18n.t("Welcome Voucher | COPE Investors Conference");
      } else if (this.props.match.params.newsId === "cleanproLaundryPass") {
        return i18n.t("Cleanpro Laundry Pass");
      } else {
        if (!this.state.loading) {
          const news = this.state.news.filter(news => news.id === this.props.match.params.newsId)[0];
          if (news) {
            let newsLang = news.news_langs.filter(news_lang => news_lang.language === this.props.language)[0]
            if (newsLang) {
              return newsLang.title
            } else {
              return news.title
            }
          }
        }
      }
    };

    const image = () => {
      if (this.props.match.params.newsId === "newUser") {
        if (STORETYPE === "washup") {
          return washupWelcome;
        } else {
          return newUser;
        }
      } else if (this.props.match.params.newsId === "referral") {
        if (STORETYPE === "washup") {
          return washupReferral;
        } else {
          return referral;
        }
      } else if (this.props.match.params.newsId === "birthday") {
        if (STORETYPE === "washup") {
          return washupBirthday;
        } else {
          return birthday;
        }
      } else if (this.props.match.params.newsId === "token") {
        return token;
      } else if (this.props.match.params.newsId === "stamp") {
        if (STORETYPE === "washup") {
          return washupStamp;
        } else {
          return stamp;
        }
      } else if (this.props.match.params.newsId === "welcomeToCleanproPlus") {
        return cleanproNewUserBanner;
      } else if (this.props.match.params.newsId === "cleanproPayMigrationNotice") {
        return cleanproMigrationBanner;
      } else if (this.props.match.params.newsId === "cuciharihari" && this.props.language === "ms") {
        return cleanproCuciBm;
      } else if (this.props.match.params.newsId === "cuciharihari") {
        return cleanproCuciEn;
      } else if (this.props.match.params.newsId === "jawabGerentiMenang") {
        return cleanproJGMBanner;
      } else if (this.props.match.params.newsId === "cleanproReferral") {
        return cleanproReferralBanner;
      } else if (this.props.match.params.newsId === "cleanproBirthday") {
        return cleanproBirthdayBanner;
      } else if (this.props.match.params.newsId === "cleanproStamp") {
        return cleanproStampBanner;
      } else if (this.props.match.params.newsId === "cleanproWelcome") {
        return cleanproWelcome;
      } else if (this.props.match.params.newsId === "cleanprocope") {
        return cleanprocope;
      } else if (this.props.match.params.newsId === "cleanproLaundryPass") {
        return cleanproLaundryPass;
      } else {
        if (!this.state.loading) {
          const news = this.state.news.filter(news => news.id === this.props.match.params.newsId)[0];
          if (news) {
            return news.image;
          }
        }
      }
    };

    const date = () => {
      if (
        this.props.match.params.newsId !== "newUser" &&
        this.props.match.params.newsId !== "referral" &&
        this.props.match.params.newsId !== "birthday" &&
        this.props.match.params.newsId !== "token" &&
        this.props.match.params.newsId !== "stamp" &&
        this.props.match.params.newsId !== "welcomeToCleanproPlus" &&
        this.props.match.params.newsId !== "cleanproPayMigrationNotice" &&
        this.props.match.params.newsId !== "cuciharihari" &&
        this.props.match.params.newsId !== "jawabGerentiMenang" &&
        this.props.match.params.newsId !== "cleanproReferral" &&
        this.props.match.params.newsId !== "cleanproBirthday" &&
        this.props.match.params.newsId !== "cleanproStamp" &&
        this.props.match.params.newsId !== "cleanproWelcome" &&
        this.props.match.params.newsId !== "cleanprocope" &&
        this.props.match.params.newsId !== "cleanproLaundryPass"
      ) {
        if (!this.state.loading) {
          const news = this.state.news.filter(news => news.id === this.props.match.params.newsId)[0];
          if (news) {
            if (news.start_date && news.end_date) {
              return (
                <p>
                  <i className="bi bi-calendar"></i>{" "}
                  {`${`${new Date(news.start_date).getDate()}/${new Date(news.start_date).getMonth() + 1}/${new Date(news.start_date).getFullYear()}`} - ${`${new Date(news.end_date).getDate()}/${new Date(news.end_date).getMonth() + 1}/${new Date(news.end_date).getFullYear()}`}`}
                </p>
              );
            }
          }
        }
      }
    };

    const description = () => {
      if (this.props.match.params.newsId === "newUser") {
        // new user voucher
        const registerVoucherSetting = this.state.voucherSetting.filter((setting) => setting.type === "Register")[0];
        return (
          <div style={{ textAlign: "justify" }}>
            <ol>
              {this.props.user.payAndGetRegVoucher && this.props.user.reg_voucher_method === "Payment" ? (
                <li>
                  {i18n.t("The voucher will be given if you completed a transaction with a minimum spending of")} {currency(this.props.user.country)}{this.props.user.regVoucherAmount}.
                </li>
              ) : this.props.user.payAndGetRegVoucher ? (
                <li>
                  {i18n.t("The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach")} {currency(this.props.user.country)}{this.props.user.regVoucherAmount} {i18n.t("and above.")}
                </li>
              ) : (
                <li>
                  {i18n.t("The voucher will be given if you registered your account successfully.")}
                </li>
              )}
              {registerVoucherSetting.voucher_expiry ? (
                <li>
                  <Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={registerVoucherSetting.voucher_duration}>
                    The voucher is valid for {{ duration: registerVoucherSetting.voucher_duration }} days from the day voucher be given.
                  </Trans>
                </li>
              ) : (
                <li>
                  {i18n.t("There is no expiration date for this voucher.")}
                </li>
              )}
              {registerVoucherSetting.voucher_dryer_use && !registerVoucherSetting.voucher_washer_use && !registerVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for dryer machine only.")}
                </li>
              ) : null}
              {registerVoucherSetting.voucher_washer_use && !registerVoucherSetting.voucher_dryer_use && !registerVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for washer machine only.")}
                </li>
              ) : null}
              {!registerVoucherSetting.voucher_washer_use && !registerVoucherSetting.voucher_dryer_use && registerVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for Drop n Go transaction only.")}
                </li>
              ) : null}
              {registerVoucherSetting.voucher_washer_use && registerVoucherSetting.voucher_dryer_use && !registerVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for dryer or washer machine.")}
                </li>
              ) : null}
              {registerVoucherSetting.voucher_washer_use && !registerVoucherSetting.voucher_dryer_use && registerVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}
                </li>
              ) : null}
              {!registerVoucherSetting.voucher_washer_use && registerVoucherSetting.voucher_dryer_use && registerVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}
                </li>
              ) : null}
              {registerVoucherSetting.voucher_washer_use && registerVoucherSetting.voucher_dryer_use && registerVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}
                </li>
              ) : null}
              <li>
                {i18n.t("When you applied the voucher during payment, it will give you")} {registerVoucherSetting.voucher_type === "Flat" ? currency(this.props.user.country) + registerVoucherSetting.voucher_amount : registerVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}
              </li>
              <li>
                {i18n.t("When promotion is on going, the voucher is not applicable.")}
              </li>
              {/* <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li> */}
              <li>
                {i18n.t("The voucher is only for customer use only, not for sale.")}
              </li>
            </ol>
          </div>
        );
      } else if (this.props.match.params.newsId === "referral") {
        // referral code voucher
        const referralVoucherSetting = this.state.referralSetting.voucher_setting;
        return (
          <div style={{ textAlign: "justify" }}>
            <ol>
              <li>
                {i18n.t("Your referral code can be found in user settings page.")}
              </li>
              <li>
                {i18n.t("When a friend / family register using your referral code, and completed a transaction with a minimum spending of")} {currency(this.props.user.country)}{this.state.referralSetting.amount_transaction}{i18n.t(", you will be rewarded automatically.")}
              </li>
              {this.state.referralSetting.number_of_referral === 999 ? (
                <li>{i18n.t("All users will have infinite referrals.")}</li>
              ) : (
                <li>
                  {i18n.t("One user only allow to have")} {this.state.referralSetting.number_of_referral} {i18n.t("referrals.")}
                </li>
              )}
              {this.state.referralSetting.stamp_eligible && this.props.user.stamp && this.props.user.combinedSetting ? (
                <li>
                  {i18n.t("You can get")} {this.state.referralSetting.stamp_combined} {i18n.t("stamp for the reward.")}
                </li>
              ) : null}
              {this.state.referralSetting.stamp_eligible && this.props.user.stamp && !this.props.user.combinedSetting && this.props.user.specificStamp === "washerOnly" ? (
                <li>
                  {i18n.t("You can get")} {this.state.referralSetting.stamp_washer} {i18n.t("washer stamp for the reward.")}
                </li>
              ) : null}
              {this.state.referralSetting.stamp_eligible && this.props.user.stamp && !this.props.user.combinedSetting && this.props.user.specificStamp === "dryerOnly" ? (
                <li>
                  {i18n.t("You can get")} {this.state.referralSetting.stamp_dryer} {i18n.t("dryer stamp for the reward.")}
                </li>
              ) : null}
              {this.state.referralSetting.stamp_eligible && this.props.user.stamp && !this.props.user.combinedSetting && this.props.user.specificStamp === "both" ? (
                <li>
                  {i18n.t("You can get")} {this.state.referralSetting.stamp_washer} {i18n.t("washer stamp and")} {this.state.referralSetting.stamp_dryer} {i18n.t("dryer stamp for the reward.")}
                </li>
              ) : null}
              {this.state.referralSetting.token_eligible && this.props.user.tokenSetting && this.state.referralSetting.token_type === "Flat" ? (
                <li>
                  {i18n.t("You can get")} {this.state.referralSetting.token_amount} {i18n.t("e-token for the reward.")}
                </li>
              ) : null}
              {this.state.referralSetting.token_eligible && this.props.user.tokenSetting && this.state.referralSetting.token_type === "Rate" ? (
                <li>
                  {i18n.t("You can get")} {this.state.referralSetting.token_amount}% {i18n.t("e-token based on transaction payment amount for the reward.")}
                </li>
              ) : null}
              {this.state.referralSetting.point_eligible && this.props.user.loyalty_point && this.state.referralSetting.point_type === "Flat" ? (
                <li>
                  {i18n.t("You can get")} {this.state.referralSetting.point_amount} {this.props.user.loyalty_point_name} {i18n.t("for the reward.")}
                </li>
              ) : null}
              {this.state.referralSetting.point_eligible && this.props.user.loyalty_point && this.state.referralSetting.point_type === "Rate" ? (
                <li>
                  {i18n.t("You can get")} {this.state.referralSetting.point_amount}% {this.props.user.loyalty_point_name} {i18n.t("based on transaction payment amount for the reward.")}
                </li>
              ) : null}
              {referralVoucherSetting.voucher_eligible ? (
                <li>
                  {i18n.t("You can get a voucher for the reward")}:
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      {i18n.t("which have")} {referralVoucherSetting.voucher_type === "Rate" ? referralVoucherSetting.voucher_amount + "%" : currency(this.props.user.country) + referralVoucherSetting.voucher_amount} {i18n.t("discount.")}
                    </li>
                    {referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_washer_use && !referralVoucherSetting.voucher_package_use ? (
                      <li>
                        {i18n.t("it can be used for dryer machine only.")}
                      </li>
                    ) : null}
                    {!referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use && !referralVoucherSetting.voucher_package_use ? (
                      <li>
                        {i18n.t("it can be used for washer machine only.")}
                      </li>
                    ) : null}
                    {!referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_washer_use && referralVoucherSetting.voucher_package_use ? (
                      <li>
                        {i18n.t("it can be used for Drop n Go transaction only.")}
                      </li>
                    ) : null}
                    {referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use && !referralVoucherSetting.voucher_package_use ? (
                      <li>
                        {i18n.t("it can be used for dryer or washer machine.")}
                      </li>
                    ) : null}
                    {referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_washer_use && referralVoucherSetting.voucher_package_use ? (
                      <li>
                        {i18n.t("it can be used for dryer machine or Drop n Go transaction.")}
                      </li>
                    ) : null}
                    {!referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use && referralVoucherSetting.voucher_package_use ? (
                      <li>
                        {i18n.t("it can be used for washer machine or Drop n Go transaction.")}
                      </li>
                    ) : null}
                    {referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_washer_use && referralVoucherSetting.voucher_package_use ? (
                      <li>
                        {i18n.t("it can be used for washer machine, dryer machine or Drop n Go transaction.")}
                      </li>
                    ) : null}
                    <li>
                      {referralVoucherSetting.voucher_expiry
                        ? <Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={referralVoucherSetting.voucher_duration}>
                          The voucher is valid for {{ duration: referralVoucherSetting.voucher_duration }} days from the day voucher be given.
                        </Trans>
                        : i18n.t("There is no expiration date for this voucher.")}
                    </li>
                    <li>
                      {i18n.t("The voucher is only for customer use only, not for sale.")}
                    </li>
                  </ol>
                </li>
              ) : null}
            </ol>
          </div>
        );
      } else if (this.props.match.params.newsId === "birthday") {
        // birthday voucher
        const birthdayVoucherSetting = this.state.voucherSetting.filter((setting) => setting.type === "Birthday")[0];
        return (
          <div style={{ textAlign: "justify" }}>
            <ol>
              <li>
                {i18n.t("The birthday voucher will be given on the first day of your birthday month.")}
              </li>
              {this.props.user.payAndGetBirthdayVoucher ? (
                <li>
                  {i18n.t("The voucher only will be given if you have exceed spending amount of")} {currency(this.props.user.country)}{this.props.user.birthdayVoucherAmount}.
                </li>
              ) : null}
              {birthdayVoucherSetting.voucher_expiry ? (
                <li>
                  <Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={birthdayVoucherSetting.voucher_duration}>
                    The voucher is valid for {{ duration: birthdayVoucherSetting.voucher_duration }} days from the day voucher be given.
                  </Trans>
                </li>
              ) : (
                <li>
                  {i18n.t("There is no expiration date for this voucher.")}
                </li>
              )}
              {birthdayVoucherSetting.voucher_dryer_use && !birthdayVoucherSetting.voucher_washer_use && !birthdayVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for dryer machine only.")}
                </li>
              ) : null}
              {birthdayVoucherSetting.voucher_washer_use && !birthdayVoucherSetting.voucher_dryer_use && !birthdayVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for washer machine only.")}
                </li>
              ) : null}
              {!birthdayVoucherSetting.voucher_washer_use && !birthdayVoucherSetting.voucher_dryer_use && birthdayVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for Drop n Go transaction only.")}
                </li>
              ) : null}
              {birthdayVoucherSetting.voucher_washer_use && birthdayVoucherSetting.voucher_dryer_use && !birthdayVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for dryer or washer machine.")}
                </li>
              ) : null}
              {birthdayVoucherSetting.voucher_washer_use && !birthdayVoucherSetting.voucher_dryer_use && birthdayVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}
                </li>
              ) : null}
              {!birthdayVoucherSetting.voucher_washer_use && birthdayVoucherSetting.voucher_dryer_use && birthdayVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}
                </li>
              ) : null}
              {birthdayVoucherSetting.voucher_washer_use && birthdayVoucherSetting.voucher_dryer_use && birthdayVoucherSetting.voucher_package_use ? (
                <li>
                  {i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}
                </li>
              ) : null}
              <li>
                {i18n.t("When you applied the voucher during payment, it will give you")} {birthdayVoucherSetting.voucher_type === "Flat" ? currency(this.props.user.country) + birthdayVoucherSetting.voucher_amount : birthdayVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}
              </li>
              <li>
                {i18n.t("When promotion is on going, the voucher is not applicable.")}
              </li>
              {/* <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li> */}
              <li>
                {i18n.t("The voucher is only for customer use only, not for sale.")}
              </li>
            </ol>
          </div>
        );
      } else if (this.props.match.params.newsId === "token") {
        // e-token
        return (
          <div style={{ textAlign: "justify" }}>
            <ol>
              {this.props.user.tokenSetting && this.props.user.epayment ? (
                <li>
                  {i18n.t('When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.')}
                </li>
              ) : null}
              {/* <li>{i18n.t("When you using your voucher during payment, you will be unable to pay with E-token.")}</li> */}
              {
                this.props.user.reload ? (
                  <li>
                    {this.props.user.stamp
                      ? i18n.t("You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.")
                      : i18n.t("You can reload your E-token by clicking the reload button in the home page and select the package to purchase.")}
                  </li>
                ) : null
              }
              <li>
                {
                  this.props.user.reload
                    ? i18n.t("You can view your E-token reload history and E-token transaction history in transaction page.")
                    : i18n.t("You can view your E-token transaction history in transaction page.")
                }
              </li>
            </ol>
          </div>
        );
      } else if (this.props.match.params.newsId === "stamp") {
        // stamp voucher
        if (this.props.user.combinedSetting) {
          // if stamp combined
          const combinedVoucherSetting = this.state.voucherSetting.filter((setting) => setting.type === "Combined Stamp")[0];
          return (
            <div style={{ textAlign: "justify" }}>
              <ol>
                <li>
                  {i18n.t("Using either dryer machine or washer machine with the minimum spending of")} {currency(this.props.user.country)}{this.props.user.stampTransactionAmount} {i18n.t("to collect one stamp.")}
                </li>
                <li>
                  {i18n.t("The voucher will be given if you have collected 10 stamps.")}
                </li>
                {combinedVoucherSetting.voucher_expiry ? (
                  <li>
                    <Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={combinedVoucherSetting.voucher_duration}>
                      The voucher is valid for {{ duration: combinedVoucherSetting.voucher_duration }} days from the day voucher be given.
                    </Trans>
                  </li>
                ) : (
                  <li>
                    {i18n.t("There is no expiration date for this voucher.")}
                  </li>
                )}
                {combinedVoucherSetting.voucher_dryer_use && !combinedVoucherSetting.voucher_washer_use && !combinedVoucherSetting.voucher_package_use ? (
                  <li>
                    {i18n.t("The voucher can be used for dryer machine only.")}
                  </li>
                ) : null}
                {!combinedVoucherSetting.voucher_dryer_use && combinedVoucherSetting.voucher_washer_use && !combinedVoucherSetting.voucher_package_use ? (
                  <li>
                    {i18n.t("The voucher can be used for washer machine only.")}
                  </li>
                ) : null}
                {!combinedVoucherSetting.voucher_dryer_use && !combinedVoucherSetting.voucher_washer_use && combinedVoucherSetting.voucher_package_use ? (
                  <li>
                    {i18n.t("The voucher can be used for Drop n Go transaction only.")}
                  </li>
                ) : null}
                {combinedVoucherSetting.voucher_dryer_use && combinedVoucherSetting.voucher_washer_use && !combinedVoucherSetting.voucher_package_use ? (
                  <li>
                    {i18n.t("The voucher can be used for dryer or washer machine.")}
                  </li>
                ) : null}
                {combinedVoucherSetting.voucher_dryer_use && !combinedVoucherSetting.voucher_washer_use && combinedVoucherSetting.voucher_package_use ? (
                  <li>
                    {i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}
                  </li>
                ) : null}
                {!combinedVoucherSetting.voucher_dryer_use && combinedVoucherSetting.voucher_washer_use && combinedVoucherSetting.voucher_package_use ? (
                  <li>
                    {i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}
                  </li>
                ) : null}
                {combinedVoucherSetting.voucher_dryer_use && combinedVoucherSetting.voucher_washer_use && combinedVoucherSetting.voucher_package_use ? (
                  <li>
                    {i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}
                  </li>
                ) : null}
                <li>
                  {i18n.t("When you applied the voucher during payment, it will give you")} {combinedVoucherSetting.voucher_type === "Flat" ? currency(this.props.user.country) + combinedVoucherSetting.voucher_amount : combinedVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}
                </li>
                <li>
                  {i18n.t("When promotion is on going, the voucher is not applicable.")}
                </li>
                {/* <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li> */}
                <li>
                  {i18n.t("The voucher is only for customer use only, not for sale.")}
                </li>
              </ol>
            </div>
          );
        } else {
          // if stamp separated
          const washerVoucherSetting = this.state.voucherSetting.filter((setting) => setting.type === "Washer Stamp")[0];
          const dryerVoucherSetting = this.state.voucherSetting.filter((setting) => setting.type === "Dryer Stamp")[0];
          if (dryerVoucherSetting.voucher_eligible && !washerVoucherSetting.voucher_eligible) {
            // if only give voucher for dryer stamp
            return (
              <div style={{ textAlign: "justify" }}>
                <ol>
                  {this.props.user.specificStamp === "dryerOnly" || this.props.user.specificStamp === "both" ? (
                    <li>
                      {i18n.t("Using dryer machine with the minimum spending of")} {currency(this.props.user.country)}{this.props.user.stampTransactionAmount} {i18n.t("to collect one stamp.")}
                    </li>
                  ) : null}
                  <li>
                    {i18n.t("The voucher will be given if you have collected 10 stamps.")}
                  </li>
                  {dryerVoucherSetting.voucher_expiry ? (
                    <li>
                      <Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={dryerVoucherSetting.voucher_duration}>
                        The voucher is valid for {{ duration: dryerVoucherSetting.voucher_duration }} days from the day voucher be given.
                      </Trans>
                    </li>
                  ) : (
                    <li>
                      {i18n.t("There is no expiration date for this voucher.")}
                    </li>
                  )}
                  {dryerVoucherSetting.voucher_dryer_use && !dryerVoucherSetting.voucher_washer_use && !dryerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for dryer machine only.")}
                    </li>
                  ) : null}
                  {!dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use && !dryerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for washer machine only.")}
                    </li>
                  ) : null}
                  {!dryerVoucherSetting.voucher_dryer_use && !dryerVoucherSetting.voucher_washer_use && dryerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for Drop n Go transaction only.")}
                    </li>
                  ) : null}
                  {dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use && !dryerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for dryer or washer machine.")}
                    </li>
                  ) : null}
                  {dryerVoucherSetting.voucher_dryer_use && !dryerVoucherSetting.voucher_washer_use && dryerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}
                    </li>
                  ) : null}
                  {!dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use && dryerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}
                    </li>
                  ) : null}
                  {dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use && dryerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}
                    </li>
                  ) : null}
                  <li>
                    {i18n.t("When you applied the voucher during payment, it will give you")} {dryerVoucherSetting.voucher_type === "Flat" ? currency(this.props.user.country) + dryerVoucherSetting.voucher_amount : dryerVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}
                  </li>
                  <li>
                    {i18n.t("When promotion is on going, the voucher is not applicable.")}
                  </li>
                  {/* <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li> */}
                  <li>
                    {i18n.t("The voucher is only for customer use only, not for sale.")}
                  </li>
                </ol>
              </div>
            );
          } else if (washerVoucherSetting.voucher_eligible && !dryerVoucherSetting.voucher_eligible) {
            // if only give voucher for washer stamp
            return (
              <div style={{ textAlign: "justify" }}>
                <ol>
                  <li>
                    {i18n.t("Using washer machine with the minimum spending of")} {currency(this.props.user.country)}{this.props.user.stampTransactionAmount} {i18n.t("to collect one stamp.")}
                  </li>
                  <li>
                    {i18n.t("The voucher will be given if you have collected 10 stamps.")}
                  </li>
                  {washerVoucherSetting.voucher_expiry ? (
                    <li>
                      <Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={washerVoucherSetting.voucher_duration}>
                        The voucher is valid for {{ duration: washerVoucherSetting.voucher_duration }} days from the day voucher be given.
                      </Trans>
                    </li>
                  ) : (
                    <li>
                      {i18n.t("There is no expiration date for this voucher.")}
                    </li>
                  )}
                  {washerVoucherSetting.voucher_dryer_use && !washerVoucherSetting.voucher_washer_use && !washerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for dryer machine only.")}
                    </li>
                  ) : null}
                  {!washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use && !washerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for washer machine only.")}
                    </li>
                  ) : null}
                  {!washerVoucherSetting.voucher_dryer_use && !washerVoucherSetting.voucher_washer_use && washerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for Drop n Go transaction only.")}
                    </li>
                  ) : null}
                  {washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use && !washerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for dryer or washer machine.")}
                    </li>
                  ) : null}
                  {!washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use && washerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}
                    </li>
                  ) : null}
                  {washerVoucherSetting.voucher_dryer_use && !washerVoucherSetting.voucher_washer_use && washerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}
                    </li>
                  ) : null}
                  {washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use && washerVoucherSetting.voucher_package_use ? (
                    <li>
                      {i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}
                    </li>
                  ) : null}
                  <li>
                    {i18n.t("When you applied the voucher during payment, it will give you")} {washerVoucherSetting.voucher_type === "Flat" ? currency(this.props.user.country) + washerVoucherSetting.voucher_amount : washerVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}
                  </li>
                  <li>
                    {i18n.t("When promotion is on going, the voucher is not applicable.")}
                  </li>
                  {/* <li>{i18n.t("The voucher cannot be used if your payment method is E-Token.")}</li> */}
                  <li>
                    {i18n.t("The voucher is only for customer use only, not for sale.")}
                  </li>
                </ol>
              </div>
            );
          } else if (washerVoucherSetting.voucher_eligible && dryerVoucherSetting.voucher_eligible) {
            // if give voucher for washer stamp and dryer stamp
            return (
              <div style={{ textAlign: "justify" }}>
                <ol>
                  {this.props.user.specificStamp === "dryerOnly" || this.props.user.specificStamp === "both" ? (
                    <li>
                      {i18n.t("Using dryer machine with the minimum spending of")} {currency(this.props.user.country)}{this.props.user.stampTransactionAmount} {i18n.t("to collect one dryer stamp.")}
                    </li>
                  ) : null}
                  {this.props.user.specificStamp === "washerOnly" || this.props.user.specificStamp === "both" ? (
                    <li>
                      {i18n.t("Using washer machine with the minimum spending of")} {currency(this.props.user.country)}{this.props.user.stampTransactionAmount} {i18n.t("to collect one washer stamp.")}
                    </li>
                  ) : null}
                  {this.props.user.specificStamp === "dryerOnly" || this.props.user.specificStamp === "washerOnly" ? (
                    <li>
                      {i18n.t("The voucher will be given if you have collected 10 stamps.")}
                    </li>
                  ) : (
                    <li>
                      {i18n.t("The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.")}
                    </li>
                  )}
                  <li>
                    {i18n.t("For the voucher given by the dryer stamps,")}
                    <ol style={{ listStyleType: "lower-alpha" }}>
                      {dryerVoucherSetting.voucher_expiry ? (
                        <li>
                          <Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={dryerVoucherSetting.voucher_duration}>
                            The voucher is valid for {{ duration: dryerVoucherSetting.voucher_duration }} days from the day voucher be given.
                          </Trans>
                        </li>
                      ) : (
                        <li>
                          {i18n.t("There is no expiration date for this voucher.")}
                        </li>
                      )}
                      {dryerVoucherSetting.voucher_dryer_use && !dryerVoucherSetting.voucher_washer_use && !dryerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for dryer machine only.")}
                        </li>
                      ) : null}
                      {!dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use && !dryerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for washer machine only.")}
                        </li>
                      ) : null}
                      {!dryerVoucherSetting.voucher_dryer_use && !dryerVoucherSetting.voucher_washer_use && dryerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for Drop n Go transaction only.")}
                        </li>
                      ) : null}
                      {dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use && !dryerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for dryer or washer machine.")}
                        </li>
                      ) : null}
                      {dryerVoucherSetting.voucher_dryer_use && !dryerVoucherSetting.voucher_washer_use && dryerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}
                        </li>
                      ) : null}
                      {!dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use && dryerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}
                        </li>
                      ) : null}
                      {dryerVoucherSetting.voucher_dryer_use && dryerVoucherSetting.voucher_washer_use && dryerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}
                        </li>
                      ) : null}
                      <li>
                        {i18n.t("When you applied the voucher during payment, it will give you")} {dryerVoucherSetting.voucher_type === "Flat" ? currency(this.props.user.country) + dryerVoucherSetting.voucher_amount : dryerVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}
                      </li>
                    </ol>
                  </li>
                  <li>
                    {i18n.t("For the voucher given by the washer stamps,")}
                    <ol style={{ listStyleType: "lower-alpha" }}>
                      {washerVoucherSetting.voucher_expiry ? (
                        <li>
                          <Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={washerVoucherSetting.voucher_duration}>
                            The voucher is valid for {{ duration: washerVoucherSetting.voucher_duration }} days from the day voucher be given.
                          </Trans>
                        </li>
                      ) : (
                        <li>
                          {i18n.t("There is no expiration date for this voucher.")}
                        </li>
                      )}
                      {washerVoucherSetting.voucher_dryer_use && !washerVoucherSetting.voucher_washer_use && !washerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for dryer machine only.")}
                        </li>
                      ) : null}
                      {!washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use && !washerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for washer machine only.")}
                        </li>
                      ) : null}
                      {!washerVoucherSetting.voucher_dryer_use && !washerVoucherSetting.voucher_washer_use && washerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for Drop n Go transaction only.")}
                        </li>
                      ) : null}
                      {washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use && !washerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for dryer or washer machine.")}
                        </li>
                      ) : null}
                      {washerVoucherSetting.voucher_dryer_use && !washerVoucherSetting.voucher_washer_use && washerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}
                        </li>
                      ) : null}
                      {!washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use && washerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}
                        </li>
                      ) : null}
                      {washerVoucherSetting.voucher_dryer_use && washerVoucherSetting.voucher_washer_use && washerVoucherSetting.voucher_package_use ? (
                        <li>
                          {i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}
                        </li>
                      ) : null}
                      <li>
                        {i18n.t("When you applied the voucher during payment, it will give you")} {washerVoucherSetting.voucher_type === "Flat" ? currency(this.props.user.country) + washerVoucherSetting.voucher_amount : washerVoucherSetting.voucher_amount + "%"} {i18n.t("discount.")}
                      </li>
                    </ol>
                  </li>
                  <li>
                    {i18n.t("When promotion is on going, all the vouchers is not applicable.")}
                  </li>
                  {/* <li>{i18n.t("All the vouchers cannot be used if your payment method is E-Token.")}</li> */}
                  <li>
                    {i18n.t("The voucher is only for customer use only, not for sale.")}
                  </li>
                </ol>
              </div>
            );
          }
        }
      } else if (this.props.match.params.newsId === "welcomeToCleanproPlus") {
        return (
          <div style={{ textAlign: "justify" }}>
            <p className="pt-2">{i18n.t("The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.")}</p>
            <p className="pt-3">{i18n.t("To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.")}</p>
            <p className="pt-3">{i18n.t("If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.")}</p>
          </div>
        );
      } else if (this.props.match.params.newsId === "cleanproPayMigrationNotice") {
        return (
          <div style={{ textAlign: "justify" }}>
            <p className="pt-2">{i18n.t("Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.")}</p>
            <p className="pt-3">{i18n.t("We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.")}</p>
            <p className="pt-3">{i18n.t("If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.")}</p>
            <p className="pt-3">{i18n.t("If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com")}</p>
          </div>
        );
      } else if (this.props.match.params.newsId === "cuciharihari") {
        return (
          <div style={{ textAlign: "justify" }}>
            <p className="pt-2">{i18n.t("Ready for a challenge?")}</p>
            <p className="pt-3">{i18n.t("Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!")}</p>
            <p className="pt-3"><b>{i18n.t("Here's how to participate:")}</b></p>
            <p className="pt-3">{i18n.t("Step 1: Scan the QR Code on the washer to launch Cleanpro Plus")}</p>
            <p>{i18n.t("Step 2: Pay for your wash with Cleanpro Plus")}</p>
            <p>{i18n.t("Step 3: Complete x10 washes within 20 days to win")}</p>
            <p className="pt-3"><b>{i18n.t("Challenge Period: 01 July - 15 September 2023")}</b></p>
            <p className="pt-3">{i18n.t("Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!")}</p>
            <p className="pt-3"><b>{i18n.t("TERMS & CONDITIONS")}</b></p>
            <p className="pt-3"><b>{i18n.t("PARTICIPATION CRITERIA")}</b></p>
            <p>{i18n.t("1) Participant needs to register an account in Cleanpro Plus web app.")}</p>
            <p>{i18n.t("2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.")}</p>
            <p>{i18n.t("3) Payment needs to be made with Cleanpro Plus web app.")}</p>
            <p>{i18n.t("4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.")}</p>
            <p>{i18n.t("5) Campaign period starts from 1st July 2023 at 00:00 and ends at 15th September 2023 at 23:59.")}</p>
            <p className="pt-3"><b>{i18n.t("WINNERS & PRIZES")}</b></p>
            <p>{i18n.t("1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.")}</p>
            <p>{i18n.t("2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.")}</p>
            <p>{i18n.t("3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.")}</p>
            <p>{i18n.t("4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.")}</p>
            <p>{i18n.t("5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.")}</p>
            <p>{i18n.t("A) XL Size - Blue bag with up to 20KG in capacity")}</p>
            <p>{i18n.t("OR")}</p>
            <p>{i18n.t("B) L Size - White bag with up to 12KG in capacity")}</p>
            <p>{i18n.t("6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.")}</p>
            <p className="pt-3"><b>{i18n.t("OTHER")}</b></p>
            <p>{i18n.t("1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.")}</p>
            <p>{i18n.t("2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.")}</p>
            <p>{i18n.t("3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.")}</p>
          </div>
        );
      } else if (this.props.match.params.newsId === "jawabGerentiMenang") {
        return (
          <div style={{ textAlign: "justify" }}>
            <p className="pt-2">{i18n.t("Answer simple questions and win credits for your next wash or dry!")}</p>
            <p className="pt-3"><b>{i18n.t("How to participate:")}</b></p>
            <p className="pt-3">{i18n.t("Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.")}</p>
            <p className="pt-3">{i18n.t("Step 2 : Answer 3 simple questions.")}</p>
            <p className="pt-3">{i18n.t("Step 3 : Submit and stand a chance to win prizes.")}</p>
            <p className="pt-3"><b>{i18n.t("Prizes to be won:")}</b></p>
            <p className="pt-3">{i18n.t("Grand Prize :")}</p>
            <p>{i18n.t("x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus")}</p>
            <p className="pt-3">{i18n.t("Consolation Prize :")}</p>
            <p>{i18n.t("X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus")}</p>
            <p className="pt-3"><b>{i18n.t("Participation prize:")}</b></p>
            <p>{i18n.t("Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.")}</p>
            <p className="pt-3">{i18n.t("If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.")}</p>
          </div>
        );
      } else if (this.props.match.params.newsId === "cleanproReferral") {
        const referralVoucherSetting = this.state.referralSetting.voucher_setting;
        const referralVoucherAmount = referralVoucherSetting.voucher_amount
        const numberOfReferral = this.state.referralSetting.number_of_referral
        const referralToken = this.state.referralSetting.token_amount
        return (
          <div style={{ textAlign: "justify" }}>
            {referralVoucherSetting.voucher_eligible && referralVoucherSetting.voucher_type === "Flat" ? <p className="pt-2"><Trans i18nKey="cleanproReferralFlatVoucher" count={referralVoucherAmount}>Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralVoucherAmount }} credits voucher.</Trans></p> : null}
            {referralVoucherSetting.voucher_eligible && referralVoucherSetting.voucher_type === "Rate" ? <p className="pt-2"><Trans i18nKey="cleanproReferralRateVoucher" count={referralVoucherAmount}>Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralVoucherAmount }}% Off voucher.</Trans></p> : null}
            {!referralVoucherSetting.voucher_eligible && this.state.referralSetting.token_eligible ? <p className="pt-2"><Trans i18nKey="cleanproReferralTokenReward" count={referralToken}>Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralToken }} credits.</Trans></p> : null}
            <p className="pt-3">{i18n.t("How to get & use code:")}</p>
            <ol>
              <li>{i18n.t('Go to menu = icon and click “Refer friends”')}</li>
              <li>{i18n.t("Share with everyone who will likely to use Cleanpro Plus.")}</li>
            </ol>
            <p className="pt-2">{i18n.t("Referral Terms & Conditions:")}</p>
            <ol>
              <li><Trans i18nKey="When your friend or family register using your referral code, and completed a transaction (a minimum spending of {{ currency }}{{ referralTransactionAmount }}) , you will be rewarded automatically.">When your friend or family register using your referral code, and completed a transaction (a minimum spending of {{ currency: currency(this.props.user.country) }}{{ referralTransactionAmount: this.state.referralSetting.amount_transaction }}) , you will be rewarded automatically.</Trans></li>
              {!referralVoucherSetting.voucher_eligible ? <li><Trans i18nKey="cleanproNumberOfReferralReward" count={numberOfReferral}>You may share with as many people as you want, but each user is ONLY able to receive {{ numberOfReferral }} referral reward.</Trans></li> : null}
              {referralVoucherSetting.voucher_eligible ? <li><Trans i18nKey="cleanproNumberOfReferral" count={numberOfReferral}>You may share with as many people as you want, but each user is ONLY able to receive {{ numberOfReferral }} referral vouchers.</Trans></li> : null}
              {referralVoucherSetting.voucher_eligible && referralVoucherSetting.voucher_washer_use && referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}</li> : null}
              {referralVoucherSetting.voucher_eligible && referralVoucherSetting.voucher_washer_use && referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li> : null}
              {referralVoucherSetting.voucher_eligible && referralVoucherSetting.voucher_washer_use && !referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}</li> : null}
              {referralVoucherSetting.voucher_eligible && !referralVoucherSetting.voucher_washer_use && referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}</li> : null}
              {referralVoucherSetting.voucher_eligible && !referralVoucherSetting.voucher_washer_use && referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for dryer machine only.")}</li> : null}
              {referralVoucherSetting.voucher_eligible && referralVoucherSetting.voucher_washer_use && !referralVoucherSetting.voucher_dryer_use && !referralVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for washer machine only.")}</li> : null}
              {referralVoucherSetting.voucher_eligible && !referralVoucherSetting.voucher_washer_use && !referralVoucherSetting.voucher_dryer_use && referralVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for Drop n Go transaction only.")}</li> : null}
              {referralVoucherSetting.voucher_eligible && referralVoucherSetting.voucher_expiry ? <li><Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={referralVoucherSetting.voucher_duration}>The voucher is valid for {{ duration: referralVoucherSetting.voucher_duration }} days from the day voucher be given.</Trans></li> : null}
              {referralVoucherSetting.voucher_eligible && !referralVoucherSetting.voucher_expiry ? <li>{i18n.t("There is no expiration date for this voucher.")}</li> : null}
              {referralVoucherSetting.voucher_eligible ? <li>{i18n.t("The voucher is for customer use only and not for sale.")}</li> : null}
            </ol>
          </div>
        )
      } else if (this.props.match.params.newsId === "cleanproBirthday") {
        const birthdayVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Birthday")[0];
        const voucherAmount = birthdayVoucherSetting.voucher_amount
        return (
          <div style={{ textAlign: "justify" }}>
            {birthdayVoucherSetting.voucher_type === "Flat" ? <p className="pt-2"><Trans i18nKey="We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ currency }}{{ voucherAmount }} credit vouchers to you on your birthday." count={voucherAmount}>We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ currency: currency(this.props.user.country) }}{{ voucherAmount: birthdayVoucherSetting.voucher_amount }} credit vouchers to you on your birthday.</Trans></p> : null}
            {birthdayVoucherSetting.voucher_type === "Rate" ? <p className="pt-2"><Trans i18nKey="cleanproBirthdayRateAmount">We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ voucherAmount }}% vouchers to you on your birthday.</Trans></p> : null}
            <p className="pt-3">{i18n.t("Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.")}</p>
            <p className="pt-3">{i18n.t("Birthday Voucher Terms & Conditions:")}</p>
            <ol>
              <li>{i18n.t("The voucher will be given on the first day of your birthday month.")}</li>
              {birthdayVoucherSetting.voucher_expiry ? <li><Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={birthdayVoucherSetting.voucher_duration}>The voucher is valid for {{ duration: birthdayVoucherSetting.voucher_duration }} days from the day voucher be given.</Trans></li> : <li>{i18n.t("There is no expiration date for this voucher.")}</li>}
              {birthdayVoucherSetting.voucher_dryer_use && birthdayVoucherSetting.voucher_washer_use && birthdayVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}</li> : null}
              {birthdayVoucherSetting.voucher_dryer_use && !birthdayVoucherSetting.voucher_washer_use && birthdayVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}</li> : null}
              {!birthdayVoucherSetting.voucher_dryer_use && birthdayVoucherSetting.voucher_washer_use && birthdayVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}</li> : null}
              {birthdayVoucherSetting.voucher_dryer_use && birthdayVoucherSetting.voucher_washer_use && !birthdayVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li> : null}
              {!birthdayVoucherSetting.voucher_dryer_use && birthdayVoucherSetting.voucher_washer_use && !birthdayVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for washer machine only.")}</li> : null}
              {birthdayVoucherSetting.voucher_dryer_use && !birthdayVoucherSetting.voucher_washer_use && !birthdayVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for dryer machine only.")}</li> : null}
              {!birthdayVoucherSetting.voucher_dryer_use && !birthdayVoucherSetting.voucher_washer_use && birthdayVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for Drop n Go transaction only.")}</li> : null}
              {birthdayVoucherSetting.voucher_type === "Flat"
                ? <li>
                  <Trans i18nKey="When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount." count={birthdayVoucherSetting.voucher_amount}>
                    When you applied the voucher during payment, it will give you {{ voucherAmount: birthdayVoucherSetting.voucher_amount }} credits ({{ currency: currency(this.props.user.country) }}{{ voucherAmount: birthdayVoucherSetting.voucher_amount }}) discount.
                  </Trans>
                </li>
                : <li>
                  <Trans i18nKey="When you applied the voucher during payment, it will give you {{ voucherAmount }}% discount.">
                    When you applied the voucher during payment, it will give you {{ voucherAmount: birthdayVoucherSetting.voucher_amount }}% discount.
                  </Trans>
                </li>}
              <li>{i18n.t("All vouchers is not valid with other ongoing promotions, discounts and offers.")}</li>
              <li>{i18n.t("The voucher is for customer use only and not for sale.")}</li>
            </ol>
          </div>
        )
      } else if (this.props.match.params.newsId === "cleanproStamp") {
        const combinedVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Combined Stamp")[0];
        const washerVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Washer Stamp")[0];
        const dryerVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Dryer Stamp")[0];
        const selectedVoucherSetting =
          this.props.user.combinedSetting ? combinedVoucherSetting :
            this.props.user.specificStamp === "washerOnly" ? washerVoucherSetting :
              this.props.user.specificStamp === "dryerOnly" ? dryerVoucherSetting :
                washerVoucherSetting.voucher_eligible ? washerVoucherSetting : dryerVoucherSetting

        const voucherAmount = selectedVoucherSetting.voucher_amount
        return (
          <div style={{ textAlign: "justify" }}>
            {selectedVoucherSetting.voucher_type === "Flat" ? <p className="pt-2"><Trans i18nKey="cleanproStampFlat" count={voucherAmount}>With every 10 e-stamps that you collected, you can claim up to {{ voucherAmount }} credits of free wash or dry session with Cleanpro Plus. Make your laundry routine not just hassle-free, but also rewarding.</Trans></p> : <p className="pt-2"><Trans i18nKey="cleanproStampRate" count={voucherAmount}>With every 10 e-stamps that you collected, you can claim up to {{ voucherAmount }}% of free wash or dry session with Cleanpro Plus. Make your laundry routine not just hassle-free, but also rewarding.</Trans></p>}
            {!this.props.user.combinedSetting && this.props.user.specificStamp === "washerOnly" ? <p className="pt-3">{i18n.t("A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.")}</p> : null}
            {!this.props.user.combinedSetting && this.props.user.specificStamp === "dryerOnly" ? <p className="pt-3">{i18n.t("A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.")}</p> : null}
            {!this.props.user.combinedSetting && this.props.user.specificStamp === "both" ? <p className="pt-3">{i18n.t("A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.")}</p> : null}
            {this.props.user.combinedSetting ? <p className="pt-3">{i18n.t("A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.")}</p> : null}
            <p className="pt-3">{i18n.t("Terms & Conditions:")}</p>
            <ol>
              <li>
                <Trans i18nKey="To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency }}{{ stampTransactionAmount }}) per session." count={this.props.user.stampTransactionAmount}>
                  To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount: this.props.user.stampTransactionAmount }} credits ({{ currency: currency(this.props.user.country) }}{{ stampTransactionAmount: this.props.user.stampTransactionAmount }}) per session.
                </Trans>
              </li>
              {!this.props.user.combinedSetting && this.props.user.specificStamp === "washerOnly" ? <li>{i18n.t("You will be automatically issued a voucher if you have collected 10 washer stamps.")}</li> : null}
              {!this.props.user.combinedSetting && this.props.user.specificStamp === "dryerOnly" ? <li>{i18n.t("You will be automatically issued a voucher if you have collected 10 dryer stamps.")}</li> : null}
              {!this.props.user.combinedSetting && this.props.user.specificStamp === "both" ? <li>{i18n.t("You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.")}</li> : null}
              {this.props.user.combinedSetting ? <li>{i18n.t("You will be automatically issued a voucher if you have collected 10 stamps.")}</li> : null}
              {selectedVoucherSetting.voucher_expiry ? <li><Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={selectedVoucherSetting.voucher_duration}>The voucher is valid for {{ duration: selectedVoucherSetting.voucher_duration }} days from the day voucher be given.</Trans></li> : <li>{i18n.t("There is no expiration date for this voucher.")}</li>}
              {selectedVoucherSetting.voucher_washer_use && selectedVoucherSetting.voucher_dryer_use && selectedVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}</li> : null}
              {selectedVoucherSetting.voucher_washer_use && selectedVoucherSetting.voucher_dryer_use && !selectedVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for dryer or washer machine.")}</li> : null}
              {selectedVoucherSetting.voucher_washer_use && !selectedVoucherSetting.voucher_dryer_use && selectedVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for washer machine or Drop n Go transaction.")}</li> : null}
              {!selectedVoucherSetting.voucher_washer_use && selectedVoucherSetting.voucher_dryer_use && selectedVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for dryer machine or Drop n Go transaction.")}</li> : null}
              {!selectedVoucherSetting.voucher_washer_use && selectedVoucherSetting.voucher_dryer_use && !selectedVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for dryer machine only.")}</li> : null}
              {selectedVoucherSetting.voucher_washer_use && !selectedVoucherSetting.voucher_dryer_use && !selectedVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for washer machine only.")}</li> : null}
              {!selectedVoucherSetting.voucher_washer_use && !selectedVoucherSetting.voucher_dryer_use && selectedVoucherSetting.voucher_package_use ? <li>{i18n.t("The voucher can be used for Drop n Go transaction only.")}</li> : null}
              {selectedVoucherSetting.voucher_type === "Flat"
                ? <li>
                  <Trans i18nKey="When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount." count={voucherAmount}>
                    When you applied the voucher during payment, it will give you {{ voucherAmount: selectedVoucherSetting.voucher_amount }} credits ({{ currency: currency(this.props.user.country) }}{{ voucherAmount: selectedVoucherSetting.voucher_amount }}) discount.
                  </Trans>
                </li>
                : <li>
                  <Trans i18nKey="When you applied the voucher during payment, it will give you {{ voucherAmount }}% discount.">
                    When you applied the voucher during payment, it will give you {{ voucherAmount: selectedVoucherSetting.voucher_amount }}% discount.
                  </Trans>
                </li>}
              <li>{i18n.t("The voucher is non-refundable and cannot be exchanged for cash; in part or in full.")}</li>
              <li>{i18n.t("This voucher is valid for single transaction only.")}</li>
              <li>{i18n.t("All vouchers is not valid with other ongoing promotions, discounts and offers.")}</li>
            </ol>
          </div>
        )
      } else if (this.props.match.params.newsId === "cleanproWelcome" || this.props.match.params.newsId === "cleanprocope") {
        const registerVoucherSetting = this.state.voucherSetting.filter(setting => setting.type === "Register")[0];
        return (
          <div style={{ textAlign: "justify" }}>
            {this.props.match.params.newsId === "cleanprocope" ? <p>{i18n.t("Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.")}</p> : null}
            <p className="pt-3"><b>{i18n.t("Terms & Conditions")}</b></p>
            <ol>
              <li>{i18n.t("Welcome Voucher is valid for one-time use only.")}</li>
              <li>{i18n.t("There is no minimum spend required for the voucher to be used.")}</li>
              <li><Trans i18nKey="Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration }} days only." count={registerVoucherSetting.voucher_duration}>Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for {{ duration: registerVoucherSetting.voucher_duration }} days only.</Trans></li>
              {this.props.user.payAndGetRegVoucher ? <li>{i18n.t("Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.")}</li> : null}
              <li>{i18n.t("Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.")}</li>
              <li>{i18n.t("The voucher is final and cannot be refunded in form of cash or credit to the user.")}</li>
              <li>{i18n.t("Cleanpro reserves the right to change the terms and conditions without prior notice.")}</li>
            </ol>
          </div>
        )
      } else if (this.props.match.params.newsId === "cleanproLaundryPass") {
        return (
          <div style={{ textAlign: "justify" }}>
            <p className="pt-2"><b>{i18n.t("Save more by purchasing the Laundry Pass,")}</b> {i18n.t("which allows you to wash and dry more clothes at a more affordable price, while enjoying additional rewards from Cleanpro Plus!")}</p>
            <p className="pt-3"><b>{i18n.t("How to Purchase Laundry Pass")}</b></p>
            <p className="pt-2">{i18n.t("Step 1: Scan the QR code to register as a Cleanpro Plus user.")}</p>
            <p className="pt-2">{i18n.t("Step 2: Choose the Laundry Pass package you wish to purchase.")}</p>
            <p className="pt-2">{i18n.t("Step 3: Make the payment through Cleanpro Plus.")}</p>
            <p className="pt-2">{i18n.t("Step 4: Enjoy washing and/or drying services at any Cleanpro outlet in Klang Valley, Malaysia (excluding schools and universities) with exclusive discounted prices!")}</p>
            <p className="pt-3"><b>{i18n.t("Terms & Conditions:")}</b></p>
            <p className="pt-2">{i18n.t('These Terms and Conditions govern the use and purchase of the Laundry Pass to enjoy special discounts on laundry services offered by Cleanpro Laundry Holdings Sdn. Bhd. (821842-P) ("Cleanpro").')}</p>
            <p className="pt-2">{i18n.t("By purchasing and/or using the Cleanpro Laundry Pass, you agree to be bound by the following terms and conditions:")}</p>
            <ol>
              <b><li>{i18n.t("Definitions")}</li></b>
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>{i18n.t('"Laundry Pass" refers to the package(s) for specific laundry services offered by Cleanpro.')}</li>
                <li>{i18n.t('"Subscriber" refers to the individual(s) purchasing and/or using the Laundry Pass.')}</li>
              </ol>
              <b><li>{i18n.t("Pass Description")}</li></b>
              <p>{i18n.t("The Laundry Pass is a promotional package that offers laundry services at any Cleanpro outlet in Klang Valley, Malaysia only (excluding schools and universities).")}</p>
              <b><li>{i18n.t("Eligibility")}</li></b>
              <p>{i18n.t('The Laundry Pass is valid for both Malaysians and non-Malaysians; and is exclusively available to Cleanpro Plus members.')}</p>
              <li>{i18n.t("The campaign of Laundry Pass is valid from")} <b>{i18n.t("15 January 2025 to 16 March 2025.")}</b></li>
              <li>{i18n.t('Each Laundry Pass subscription is')} <b>{i18n.t('valid for 31 days from the start date,')}</b> {i18n.t('allowing subscribers to use the package')} <b>{i18n.t('up to 4 times per day.')}</b> {i18n.t('During the promotional period, you can repurchase the package unlimited times, but only after fully utilizing your current subscription.')}</li>
              <li>{i18n.t('The Laundry Pass is valid for any weight or type of wash or dry, except for washes & drying using the 25kg and above machine.')}</li>
              <li>{i18n.t('Unused wash or dry session(s) from the Laundry Pass cannot be carried forward to the next purchase, and are non-refundable, non-exchangeable for cash, and non-transferable.')}</li>
              <li>{i18n.t('The Laundry Pass is valid for washing and/or drying standard laundry items only (e.g., clothing, linens), excluding large items (e.g., rugs), heavily soiled items (e.g., pet bedding), or specialty items requiring special treatment.')}</li>
              <li>{i18n.t('The Laundry Pass can be cancelled within the first 7 days of subscription for a full refund, provided the subscriber has not used the Laundry Pass during this period. No refunds or transfers will be issued after the first 7 days.')}</li>
              <li>{i18n.t('Subscribers will receive an email reminder before their Laundry Pass subscription is about to expire.')}</li>
              <li>{i18n.t("This promotion is not valid in conjunction with the '10 Free 1 e-Stamp Campaign'.")}</li>
              <li>{i18n.t("Any misuse of the Laundry Pass will result in the immediate termination of the subscription without prior notice. Payments for the Laundry Pass are non-refundable, non-exchangeable for cash, and non-transferable.")}</li>
              <li>{i18n.t("Payment must be made in full at the time of subscription via Cleanpro Plus.")}</li>
              <li>{i18n.t("For any questions or assistance regarding the Laundry Pass, please email Cleanpro Customer Service at enquiry@cleanpro.asia.")}</li>
              <li>{i18n.t("Cleanpro reserves the right to change the terms and conditions at any time without prior notice.")}</li>
            </ol>
            <div className="text-center mt-3">
              <button
                onClick={(e) => this.props.history.push("/user/subscription")}
                className="m-0 font-weight-bold btn big-button"
              >
                {i18n.t("Purchase")}
              </button>
            </div>
          </div>
        )
      } else {
        if (!this.state.loading) {
          const news = this.state.news.filter((news) => news.id === this.props.match.params.newsId)[0];
          if (news) {
            let newsLang = news.news_langs.filter(news_lang => news_lang.language === this.props.language)[0]
            return (
              <div className="custom-news" style={{ textAlign: "justify" }}>
                <div dangerouslySetInnerHTML={{ __html: newsLang ? newsLang.description : news.description }}></div>
              </div>
            )
          }
        }
      }
    };

    const renderDetails = () => {
      if (this.state.loading) {
        return (
          <div className="text-center">
            <div
              className={STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"}
              role="status"
            >
              <span className="sr-only">Loading ...</span>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <img
              src={image()}
              alt="news"
              className="img-fluid"
              style={{ display: "block", margin: "auto" }}
            />
            <div id="user-body">
              <h2 className="pt-2">{title()}</h2>
              {STORETYPE !== "cleanpro" ? date() : null}
              {STORETYPE !== "cleanpro" ? <h4 className="pt-2">{i18n.t("Description")}</h4> : null}
              {description()}
            </div>
          </div>
        );
      }
    };

    return (
      <div id="news-details-page">
        <div id="user-header">
          <h5>
            <i
              onClick={(e) => this.props.history.goBack()}
              className="bi bi-chevron-left me-3"
            ></i>{" "}
            <strong>{title()}</strong>
          </h5>
        </div>
        {renderDetails()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    operatorId: state.user.operatorId,
    language: state.user.language,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(NewsDetails);
