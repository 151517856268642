import React, { useState, useEffect } from 'react';

import StartScreen from './laundro/StartScreen';
import Register from './laundro/Register';
import SelectService from './laundro/SelectService';
import Machines from './laundro/Machines';
import Payment from './laundro/Payment';

const LaundroDashboard = () => {

    const [page, setPage] = useState('Start');
    const [machineType, setMachineType] = useState('');
    const [user, setUser] = useState(null);
    const [sendThis, setSendThis] = useState(null);
    const [machine, setMachine] = useState(null);
    const [endpoint, setEndpoint] = useState(null);

    useEffect(() => {
        const disableZoom = (e) => {
            if (e.ctrlKey || e.metaKey || e.key === 'Meta' || e.key === 'Control') {
                e.preventDefault();
            }
        };

        const disablePinchZoom = (e) => {
            if (e.touches && e.touches.length > 1) {
                e.preventDefault();
            }
        };

        const disableContextMenu = (e) => {
            e.preventDefault();
        };

        const disableTextSelection = (e) => {
            e.preventDefault();
        };

        document.addEventListener('wheel', disableZoom, { passive: false });
        document.addEventListener('keydown', disableZoom, { passive: false });
        document.addEventListener('touchmove', disablePinchZoom, { passive: false });
        document.addEventListener('touchstart', disablePinchZoom, { passive: false });

        document.addEventListener('contextmenu', disableContextMenu, { passive: false });

        document.addEventListener('selectstart', disableTextSelection);

        return () => {
            document.removeEventListener('wheel', disableZoom);
            document.removeEventListener('keydown', disableZoom);
            document.removeEventListener('touchmove', disablePinchZoom);
            document.removeEventListener('touchstart', disablePinchZoom);
            document.removeEventListener('contextmenu', disableContextMenu);
            document.removeEventListener('selectstart', disableTextSelection);
        };
    }, []);

    const userLogout = () => {
        setUser(null);
        setPage('Start');
    };

    const handleMachineTypeSelect = (type) => {
        setMachineType(type);
        setPage('Machines');
    };

    return (
        <div>
            {page === 'Start' && <StartScreen setPage={setPage}/>}
            {page === 'Option' && <Register setPage={setPage} setUser={setUser} />}
            {page === 'SelectService' && (
                <SelectService
                    onMachineTypeSelect={handleMachineTypeSelect}
                    setPage={setPage}
                    user={user}
                />
            )}
            {page === 'Machines' && (
                <Machines
                    machineType={machineType}
                    setPage={setPage}
                    user={user}
                    userLogout={userLogout}
                    setSendThis={setSendThis}
                    machine={machine}
                    setMachine={setMachine}
                    setEndpoint={setEndpoint}
                />
            )}
            {page === 'Payment' && <Payment setPage={setPage} sendThis={sendThis} machine={machine} endpoint={endpoint} user={user} userLogout={userLogout} />}
        </div>
    );
};

export default LaundroDashboard;